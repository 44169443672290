import React, { useState, useEffect } from 'react';
import COLORS from "../../resources/colors"


export default function InfoCell(props) { 

    let id = props.id ? props.id : "infocell"
    let textColor = props.textColor || COLORS.white

    return(
        <div id={id}>
            <div className="Content">

            <div className="ImageContent">
             <img className="Image" src={props.imageUrl}></img>   
            </div>
            <h1 className="Title">{props.title}</h1>
            <h1 className="Paragraph">{props.paragraph}</h1>
                
            </div>
            <style jsx="true">{`

                #${id} .Content { 
                    font-family: "Helvetica Neue", "Arial";
                    width: 100%;   
                }

                #${id} .ImageContent { 
                    width: 25%;
                    padding: 0vw 0vw 1vw 0vw;
                    margin: auto;
                }

                #${id} .Image { 
                    height: 100%;
                }

                #${id} img { 
                    width: 100%;
                    object-fit: cover;
                }

                #${id} .Title { 
                    font-weight: 600;
                    font-size: 1.07vw; 
                    text-align: center;
                    margin: 0px;
                    color: ${textColor};
                    padding: 0.595vw 0px 0px 0px;  
                }

                #${id} .Paragraph { 
                    font-weight: 400;
                    font-size: 0.95vw;
                    text-align: center;
                    line-height: 1.48vw;
                    margin: 0px;
                    padding: 0.7vw 0px 0px 0px;
                    color: ${textColor};
                    opacity: 0.8;
                    line-height: 1.6vw;
                }

                @media (max-width: 640px) { 
                    #${id} .Content { 
                        font-family: "Helvetica Neue", "Arial";
                        width: 70vw;  
                        margin: 0px 15vw;   
                    }
    
                    #${id} .ImageContent { 
                        width: 15vw;
                        margin: auto;
                        padding: 0vw 0vw 4vw 0vw;
                    }
    
                    #${id} .Image { 
                        height: 100%;
                    }
    
                    #${id} img { 
                        width: 100%;
                        object-fit: cover;
                    }
    
                    #${id} .Title { 
                        font-weight: 600;
                        font-size: 3.5vw; 
                        text-align: center;
                        margin: 0px;
                        padding: 1.5vw 0px 0px 0px;  
                    }
    
                    #${id} .Paragraph { 
                        font-weight: 400;
                        font-size: 3.5vw;
                        text-align: center;
                        line-height: 5vw;
                        margin: 0px;
                        padding: 4vw 0px 0px 0px;
                        line-height: 5vw;
                    }
                }
            `}</style>
        </div>
    )
}   